import API from '@aws-amplify/api';
import { get } from 'lodash';

// use JSON stringify to generate the correct format value for the additional field header
const ADDITIONAL_FIELD_HANDLES_VALUE = JSON.stringify(['handles']);

const THREE_D_ID_API = {
  BASE_URI: 'three-d-id-api'
};

export const getApps = async () => {
  try {
    const path = '/apps';
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};


export const getUsers = async (
  hasPagination?: boolean,
  startAfter?: string | boolean,
  userIDs?: string[]
) => {
  try {
    const path = startAfter ? `/users?startAfter=${startAfter}` : '/users';
    const myInit = {
      headers: hasPagination ? {paginate: 'true', 'additional-fields': ADDITIONAL_FIELD_HANDLES_VALUE} : {'additional-fields': ADDITIONAL_FIELD_HANDLES_VALUE},
      queryStringParameters: userIDs ? {id: userIDs} : {}
    };

    return await API.get(
      THREE_D_ID_API.BASE_URI,
      path,
      myInit
    );
  } catch (e) {
    console.error(e);
  }
};

export const getUsersApp = async (userIDs?: string[]) => {
  try {
    // Check if userIDs is defined and not empty
    if (!userIDs || userIDs.length === 0) {
      return [];
    }

    // Use Promise.all to wait for all API calls to complete
    const userList = await Promise.all(userIDs.map(id => getUser(id)));

    // Return the list of users after all promises have been resolved
    return userList;
  } catch (e) {
    console.error(e);
    // Handle the error and return an empty list in case of failure
    return [];
  }
};

export const getUserApp = async (appId, userHandle) => {
  try {
    const path = `/apps/${appId}/users/${userHandle}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};


export function getUsersPager() {
  var continueFrom = null;
  return async function () {
    if (continueFrom == -1)
      return null;
    const page = await getUsers(true, continueFrom);
    continueFrom = page.continueFrom ? page.continueFrom : -1;
    return page.users;
  }
}

export const getScans = async (
  hasPagination?: boolean,
  startAfter?: string | boolean,
  count?: number,
  fromDate?: string,
  toDate?: string,
  sortOrder?: string,
) => {
  try {
    if (!count || count < 1)
      count = 15;
    let path = `/scans?count=${count}`;
    if (startAfter)
      path += `&startAfter=${startAfter}`;
    if (fromDate)
      path += `&from=${fromDate}`
    if (toDate)
      path += `&to=${toDate}`
    if (sortOrder)
      path += `&sortOrder=${sortOrder}`
    return await API.get(
      THREE_D_ID_API.BASE_URI,
      path,
      hasPagination
        ? {
            headers: {
              paginate: 'true'
            }
          }
        : {}
    );
  } catch (e) {
    console.error(e);
  }
};

export const getUserScans = async (
  userId,
  hasPagination?: boolean,
  startAfter?: string | boolean,
  count?: number,
  fromDate?: string,
  toDate?: string,
  sortOrder?: string,
) => {
  try {
    if (!count || count < 1)
      count = 15;
    let path = `/users/${userId}/scans?count=${count}`;
    if (startAfter)
      path += `&startAfter=${startAfter}`;
    if (fromDate)
      path += `&from=${fromDate}`
    if (toDate)
      path += `&to=${toDate}`
    if (sortOrder)
      path += `&sortOrder=${sortOrder}`
    return await API.get(
      THREE_D_ID_API.BASE_URI,
      path,
      hasPagination
        ? {
            headers: {
              paginate: 'true'
            }
          }
        : {}
    );
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export function getScansPager(isDescendingPager=true, fromDatetime=null, toDatetime=null) {
  var continueFrom = null;
  const count = 15;
  const sortOrder = isDescendingPager ? null : 'asc';
  return async function (userId=null) {
    if (continueFrom == -1)
      return null;
    const page = userId
      ? await getUserScans(userId, true, continueFrom, count, fromDatetime, toDatetime, sortOrder)
      : await getScans(true, continueFrom, count, fromDatetime, toDatetime, sortOrder);

    continueFrom = page.continueFrom ? page.continueFrom : -1;
    return page.scans;
  }
}

export const getScanImage = async (
  scanId,
  query = 'body-mesh?format=image/png',
  prefix = ''
) => {
  try {
    const path = `/scans/${scanId}/data/${query}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, {
      headers: {
        'file-prefix': prefix
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const getAppQuery = async (appId) => {
  try {
    const path = `/apps/${appId}/dataQueries`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async (userId) => {


  try {
    const path = `/users/${userId}`;
    const myInit = {
      headers: {'additional-fields': ADDITIONAL_FIELD_HANDLES_VALUE}
    };
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return ex.response;
  }
};

export const getCoreMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/core-measures`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getAppMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/app-measures`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getAppDateQueries = async (appId: string) => {
  try {
    const path = `/apps/${appId}/dataQueries`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const getDerivedData = async (scanId: string, query: string) => {
  try {
    const path = `/scans/${scanId}/data/${query}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const deleteScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    return await API.del(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export const getUserCountryCode = async () => {
  const res = await fetch(process.env.REACT_APP_IPAPI_URL);
  const countryCode = await res.text();

  return countryCode.toLowerCase();
};


const assetsUrl = process.env.REACT_APP_ASSETS_URL;

export const getAssetsFile = async (id: string) => {
  // add random parameter to make sure we get non-cached version
  const random = Math.random() * 10000000 + '';

  try {
    const res = await fetch(`${assetsUrl}/${id}/${id}.json?random=${random}`);
    if (res.statusText === 'Forbidden') {
      throw new Error();
    }
    return res.json();
  } catch {
    return null;
  }
};


export const getDefaultAssetsFile = async () => {
  // add random parameter to make sure we get non-cached version
  const random = Math.random() * 10000000 + '';
  try {
    const res = await fetch(
      `${assetsUrl}/default/default.json?random=${random}`
    );
    if (res.statusText === 'Forbidden') {
      throw new Error();
    }
    return res.json();
  } catch {
    return null;
  }
};
