import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useCallback, useState } from 'react';
import Switch from 'react-switch';
import { consentKey, ConsentStatus } from '../gtag/constants';
import { GoogleTagManagerSetter } from '../gtag/GoogleTagManagerSetter';

const useStyles = createUseStyles({
  modalWrapper: {
    width: '100%',
    color: '#fff',
    backgroundColor: '#1C1D39',
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    padding: '16px 45px 16px 20px',
    zIndex: 100,
    transition: 'transform .25s ease-in-out,opacity .25s ease-in-out',
    transform: 'translateY(100%)',
    opacity: 0
  },
  showModal: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  closeButton: {
    position: 'absolute',
    top: '50%',
    right: 20,
    width: 22,
    height: 22,
    zIndex: 100,
    background: 'transparent',
    transform: 'translateY(-50%)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:before, &:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      height: 2,
      top: 10,
      left: 0,
      background: '#fff',
      borderRadius: '50px'
    },
    '&:before': {
      transform: 'rotate(45deg)'
    },
    '&:after': {
      transform: 'rotate(-45deg)'
    }
  },
  modalInfo: {
    marginRight: 15,
    '& span': {
      paddingRight: 10
    },
    '& a': {
      color: '#fff'
    }
  },
  modalButtons: {
    minWidth: 180,
    '& button': {
      margin: '0 5px'
    }
  },
  label: {
    borderBottom: '1px solid #dee2e6',
    '& p': {
      fontSize: 12,
      marginTop: 10
    }
  }
});
interface SettingModalProps extends ModalProps {
  onSave: (isAccepted: boolean) => void;
}
interface Checkbox {
  label: string;
  text: string;
  checked: boolean;
  disabled?: boolean;
}

const SettingModal: React.FC<SettingModalProps> = ({ onSave, ...props }) => {
  const styles = useStyles();

  const [checkboxes, setCheckboxes] = useState<Checkbox[]>([
    {
      label: 'Essential Cookies',
      text: 'These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled.',
      checked: true,
      disabled: true
    },
    {
      label: 'Enable Analytics Cookies',
      text: 'These cookies help us to understand how visitors interact with our website, discover errors and provide a better overall analytics.',
      checked: false
    }
  ]);
  const handleCheckboxChange = (index) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox, i) =>
        i === index ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleSave = () => {
    onSave(checkboxes.every((c) => c.checked));
    props.onHide();
  };
  return (
    <Modal {...props} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Advanced Cookie Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {checkboxes.map((c, idx) => (
          <label
            className={`d-flex justify-content-sm-between mb-3 ${styles.label}`}
          >
            <div className={'me-3'}>
              <span>{c.label}</span>
              <p>{c.text}</p>
            </div>
            <Switch
              onChange={() => handleCheckboxChange(idx)}
              checked={c.checked}
              disabled={c.disabled}
              onColor={'#326DB5'}
            />
          </label>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export const CookieModal: React.FC = () => {
  const styles = useStyles();

  const isConsentGranted = !!localStorage.getItem(consentKey);

  const [isShowModal, setIsShowModal] = useState<boolean>(!isConsentGranted);
  const [isGACookieAccepted, setIsGACookieAccepted] =
    useState<boolean>(isConsentGranted);
  const [isShowSettingModal, setIsShowSettingModal] = useState<boolean>(false);

  const handleAccept = () => {
    setIsShowModal(false);
    setIsGACookieAccepted(true);
    localStorage.setItem(consentKey, ConsentStatus.Granted);
  };

  const handleSettingSave = (isAccepted: boolean) => {
    setIsShowModal(false);
    setIsGACookieAccepted(isAccepted);
    if (isAccepted) {
      localStorage.setItem(consentKey, ConsentStatus.Granted);
    }
  };

  const onHideSettingModal = useCallback(
    () => setIsShowSettingModal(false),
    []
  );

  return (
    <>
      <div
        className={`d-flex justify-content-sm-between align-items-center ${
          styles.modalWrapper
        } ${isShowModal && styles.showModal}`}
      >
        <div
          className={styles.closeButton}
          onClick={() => setIsShowModal(false)}
        ></div>
        <div className={styles.modalInfo}>
          <span>
            We use cookies on our website to see how you interact with it. By
            accepting, you agree to our use of such cookies.
          </span>
          <a href="https://www.sizestream.com/privacypolicy" target={'_blank'}>
            See Privacy Policy
          </a>
        </div>
        <div className={styles.modalButtons}>
          <Button
            onClick={() => setIsShowSettingModal(true)}
            variant={'outline-primary'}
          >
            Settings
          </Button>
          <Button onClick={handleAccept}>Accept</Button>
        </div>
      </div>
      <SettingModal
        show={isShowSettingModal}
        onHide={onHideSettingModal}
        onSave={handleSettingSave}
      />
      <GoogleTagManagerSetter consentGiven={isGACookieAccepted} />
    </>
  );
};
