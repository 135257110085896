import * as React from 'react';
import { useEffect, createContext, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { withAuthenticator, translations } from '@aws-amplify/ui-react';
import { createUseStyles } from 'react-jss';
import '@aws-amplify/ui-react/styles.css';

import * as config from './config.json';
import './style.scss';
import Header from './components/Header';
import {
  getAppQuery,
  getApps,
  getAssetsFile,
  getDefaultAssetsFile
} from './api';
import { ScanTablePage } from './ScanTablePage/ScanTablePage';
import WrapComponent from './components/WrapComponent';
import Toast, { ToastProps } from './components/Toast';
import { CookieModal } from './components/CookieModal';

I18n.putVocabularies(translations);

const originalMessage =
  "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
const translatedMessage = 'Username cannot have whitespace.';

I18n.putVocabularies({
  en: {
    [originalMessage]: [translatedMessage],
    Password: 'Passphrase',
    ['Reset your password']: 'Reset your passphrase',
    ['New Password']: 'New Passphrase',
    ['Confirm Password']: 'Confirm Passphrase',
    ['Forgot your password?']: 'Forgot your passphrase?',
    ['Password does not conform to policy: Password not long enough']:
      'Passphrase does not conform to policy: Passphrase must be a minimum of 16 characters'
  }
});

const myFunction_set = () => {
  const r: Document['rootElement'] = document.querySelector(':root');
  r.style.setProperty('--amplify-colors-teal-80', '#326DB5');
  r.style.setProperty('--amplify-colors-teal-90', '#3578cb');
  r.style.setProperty('--amplify-colors-teal-10', '#e9f3ff');
};

myFunction_set();

const useStyles = createUseStyles({
  appWrap: {
    '& [data-amplify-authenticator]': {
      width: '100% !important',
      maxWidth: '100% !important'
    }
  }
});

Amplify.configure(config);

export const AppContext = createContext(null);
export const ToastContext = createContext(null);
export const AssetsContext = React.createContext(null);

const GlobalProvider = ({ children }) => {
  const [appData, setAppData] = useState(null);
  const [toast, setToast] = useState<ToastProps | null>(null);
  const [assetsDate, setAssetsDate] = useState(null);

  useEffect(() => {
    const getAppData = async () => {
      const response = await getApps();
      const res = await getAssetsFile(response[0].id);
      if (res) {
        setAssetsDate(res);
      } else {
        const defaultRes = await getDefaultAssetsFile();
        setAssetsDate(defaultRes);
      }
      const dataQueries = await getAppQuery(response[0].id);
      setAppData({ ...response[0], dataQueries });
    };
    getAppData();
  }, []);

  if (!appData) return null;

  return (
    <AssetsContext.Provider value={assetsDate}>
      <AppContext.Provider value={{ ...appData }}>
        <ToastContext.Provider value={{ setToast }}>
          {children}
          {toast && <Toast {...toast} setToast={setToast} />}
        </ToastContext.Provider>
      </AppContext.Provider>
    </AssetsContext.Provider>
  );
};

const AuthedApp = (props) => {
  if (props.user) {
    return (
      <BrowserRouter>
        <GlobalProvider>
          <Header />
          <Routes>
            <Route path="/" element={<WrapComponent />}>
              <Route index element={<ScanTablePage />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: '1rem' }}>
                    <p>There&apos;s nothing here!</p>
                  </main>
                }
              />
            </Route>
          </Routes>
          <CookieModal />
        </GlobalProvider>
      </BrowserRouter>
    );
  }
  return null;
};

const services = {
  async handleSignIn(formData: { username: string; password: string }) {
    return Auth.signIn({
      username: formData.username.replace(/ /g, ''),
      password: formData.password
    });
  }
};

const components = {
  Header: () => (
    <div
      style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}
    >
      <img
        alt="Amplify logo"
        src="img/logo.png"
        style={{ width: '100%', maxWidth: '350px', objectFit: 'contain' }}
      />
    </div>
  )
};

const Authenticator = withAuthenticator(AuthedApp, {
  hideSignUp: true,
  services: services,
  components
});

const AuthenticatorContainer = () => {
  const styles = useStyles();
  return (
    <div className={styles.appWrap}>
      <Authenticator />
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AuthenticatorContainer />
  </React.StrictMode>,
  document.getElementById('root')
);
