import * as React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { AppContext, ToastContext } from '..';
import Loading from '../components/Loading';
import { PhoneInputField } from '../components/PhoneInputField';
import { validatePhoneNumber } from './validations';
import { getUserApp, getUserScans } from '../api';
import { generateDataForTable , generateDataForAnonymousTable } from './utils'; // Import the utility function to format data
import { ScansTable } from './ScansTable'; // Import ScansTable to display results

const useStyles = createUseStyles({
  form: {
    padding: '50px 10px',
    maxWidth: '450px',
    margin: '0 auto',
  },
});

export type SearchType = 'email' | 'phone' | 'subjectid' | 'qrcode';

interface SearchByContactDataProps {
  searchBy: SearchType;
  searchPrompt: string;
  searchHandler: (userId: string) => void;
}

export const SearchByContactData: React.FC<SearchByContactDataProps> = ({
  searchBy,
  searchPrompt,
  searchHandler
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputState, setInputState] = useState('');
  const [userProfile, setUserProfile] = useState<any>(null);
  const [userScans, setUserScans] = useState<any>(null);
  const [tableData, setTableData] = useState<any[]>([]); // State to hold table data
  const [countryData, setCountryData] = useState<{ countryCode: string }>({ countryCode: 'US' });
  const styles = useStyles();
  const { setToast } = useContext(ToastContext);
  const { id } = useContext(AppContext);

  const getUserAndScans = useCallback(async () => {
    if (!inputState) {
      setIsLoading(false);
      return;
    }

    try {
      const profileResponse = await getUserApp(id, inputState);
      if (!profileResponse) {
        setToast({ text: `No user found for ${inputState}` });
        setIsLoading(false);
        return;
      }

      setUserProfile(profileResponse);

      const scansResponse = await getUserScans(profileResponse.id);

      // Ensure every scan's user is present in the users object
      const usersObj = { [profileResponse.id]: profileResponse };
      scansResponse.forEach(scan => {
        if (!usersObj[scan.user]) {
          usersObj[scan.user] = profileResponse;  // Use the same user profile if all scans belong to the same user
        }
      });

      if  (searchBy === 'phone' || searchBy === 'email') {
        var formattedData = generateDataForTable({
          scans: scansResponse,
          users: usersObj,  // Pass the complete users object
          show_handle_contact_only: 'false', // Pass the correct value based on your app logic
          appId: id,
        });

      } else {
        
        var formattedData = generateDataForAnonymousTable({
          scans: scansResponse,
          users: usersObj,  // Pass the complete users object
          show_handle_contact_only: 'true', // Pass the correct value based on your app logic
          appId: id,
          subject_id: inputState
        });
      }

      setTableData(formattedData); // Update the tableData with formatted data

    } catch (error) {
      setToast({ text: `Error fetching user or scans: ${error.message}` });
    } finally {
      setIsLoading(false);
    }
  }, [inputState, id, setToast]);

  const searchSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const trimmedInputState = inputState.trim();
    if (!trimmedInputState) {
      return setToast({ text: "This field can't be empty!" });
    }

    if (searchBy === 'phone' && !validatePhoneNumber(trimmedInputState, countryData.countryCode)) {
      return setToast({ text: `Phone number isn't valid` });
    }

    setIsLoading(true);
    setInputState(trimmedInputState);
  };

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState(e.target.value);
  };

  const phoneChangeHandler = (phoneNumber: string) => {
    // Ensure the phone number starts with a "+"
    const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
    setInputState(formattedPhoneNumber);
  };
  

  useEffect(() => {
    if (isLoading) {
      getUserAndScans();
    }
  }, [isLoading, getUserAndScans]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Form className={styles.form} onSubmit={searchSubmitHandler}>
        <h4>Enter user {searchPrompt}</h4>
        <InputGroup className="mb-3">
          {searchBy === 'phone' ? (
            <PhoneInputField
              value={inputState}
              handleChange={phoneChangeHandler}
              setCountryData={setCountryData}
              ariaLabel="Recipient's phone number"
              ariaDescribedby="basic-addon2"
            />
          ) : (
            <FormControl
              onChange={emailChangeHandler}
              value={inputState}
              name={searchBy}
              placeholder={searchPrompt}
              aria-label="Recipient's email"
              aria-describedby="basic-addon2"
            />
          )}
          <Button type="submit">Search</Button>
        </InputGroup>
      </Form>

      {/* Render the scans data in a table once the data is available */}
      {tableData.length > 0 && (
        <ScansTable
          tableData={tableData}
          sortHandler={() => {}}
          isSortedDesc={true}
          isFullyLoaded={true}
          pagingHandler={() => {}}
          scrollHeight={400} // example scroll height
          scrollPositionHandler={() => {}}
        />
      )}
    </>
  );
};
