import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { getScanImage, getAppMeasurements, getUser } from '../api';
import { AppContext } from '..';
import ScanPDF from './ScanPDF';
import PDFDownload from './PDFDownload';
import { useGenerateDataURL } from '../hooks/common';
import API from '@aws-amplify/api';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {
  download,
  getBaseFileNameFromPresignedurl
} from '../ScanTablePage/utils';
import ReactGA from 'react-ga4';
import { hashStringWithSHA256 } from '../gtag/utils';

const FITNESS = 'fitness';

const formatDateForFile = (dateString: string): string => {
  const date = new Date(dateString.replace(/ /g, 'T'));

  return [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
    (date.getHours() % 12 ? date.getHours() % 12 : 12)
      .toString()
      .padStart(2, '0'),
    date.getMinutes().toString().padStart(2, '0'),
    date.getSeconds().toString().padStart(2, '0'),
    date.getHours() < 12 ? 'AM' : 'PM'
  ].join('_');
};

interface DownloadButtonProps {
  scanId: string;
  userId: string;
  date: string;
  contact: string;
}
export const DownloadButton: React.FC<DownloadButtonProps> = ({
  scanId,
  userId,
  date,
  contact
}) => {
  const { dataQueries } = useContext(AppContext);
  const [combinedDataQueries, setCombinedDataQueries] = useState<Array<any>>([
    ...dataQueries
  ]);
  // Leaving out fitnessConfig from combined data queries for this release, it is not ready for release
  // but leaving in code to add it back in the future when it is ready
  //  const [combinedDataQueries, setCombinedDataQueries] = useState<Array<any>>([
  //    ...dataQueries,
  //    fitnessConfig
  //  ]);

  const [showDownloadErrorToast, setShowDownloadErrorToast] = useState(false);

  useEffect(() => {
    if (dataQueries) {
      setCombinedDataQueries([...dataQueries]);
      // Leaving out fitnessConfig from combined data queries for this release, it is not ready for release
      // but leaving in code to add it back in the future when it is ready
      //setCombinedDataQueries([...dataQueries, fitnessConfig]);
    }
  }, [dataQueries]);

  const getScanData = async ({ scanId, customerId, query }) => {
    ReactGA.event('downloaded_selected', {
      downloaded_data_id: hashStringWithSHA256(customerId),
      query: query
    });

    const prefix = `${scanId}-${formatDateForFile(date)}-`;
    const response = await getScanImage(scanId, query, prefix);
    if ('output' in response) {
      if ('url' in response.output) {
        try {
          // grab the file blob from the pre-signed url
          let blob = await fetch(response.output.url, {}).then((r) => r.blob());

          // capture the base query filename and file extension out of the pre-signed url
          let base_file_name_and_extension = getBaseFileNameFromPresignedurl(
            response.output.url
          );

          // generate the unique friendly file name prefix
          // contact if we are not using third party id, otherwise we use the subject id
          let unique_file_prefix = `${contact}-${formatDateForFile(date)}-`;

          // replace any illegal filename characters
          let safe_file_name = (
            unique_file_prefix + base_file_name_and_extension
          ).replaceAll('[\\\\/:*?"<>|]', '_');

          // save locally with friendly file name
          download(unique_file_prefix + base_file_name_and_extension, blob);
        } catch (ex) {
          console.error('error: ' + ex.toString());
          setShowDownloadErrorToast(true);
          return ex.response;
        }
      }
    }
  };

  const toggleToast = () => {
    setShowDownloadErrorToast((flag) => !flag);
  };

  const handleOnClickItem = (dataQuery) => {
    ReactGA.event('downloaded_selected', {
      query: dataQuery.customHandlingData.type
    });
  };

  if (!dataQueries) return null;

  return (
    <>
      <DropdownButton variant="secondary" title="Download">
        {combinedDataQueries.map((dataQuery) => {
          if (dataQuery.isCustomHandled) {
            return (
              <Dropdown.Item
                key={dataQuery.customHandlingData.type}
                onClick={() => handleOnClickItem(dataQuery)}
              >
                {dataQuery.displayName} {dataQuery.isCustomHandled}
              </Dropdown.Item>
            );
          } else if (dataQuery.query && dataQuery.query !== 'app-measures') {
            return (
              <Dropdown.Item
                key={dataQuery.query}
                onClick={() =>
                  getScanData({
                    scanId: scanId,
                    customerId: userId,
                    query: dataQuery.query
                  })
                }
              >
                {dataQuery.displayName}
              </Dropdown.Item>
            );
          }
        })}
      </DropdownButton>
      <ToastContainer className="p-3" position="top-end" style={{ zIndex: 1 }}>
        <Toast
          show={showDownloadErrorToast}
          onClose={toggleToast}
          animation
          autohide
          bg="danger"
        >
          <Toast.Header className="justify-content-between">
            Failed to process download.
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </>
  );
};
