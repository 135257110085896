import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import ReactGA from 'react-ga4';

interface SurveyModalProps {
  csat_url: string;
  updateSurveyLastSeenDate: () => void;
}

// add parameter passed in to SurveyModal
//function SurveyModal(csat_url: string) {
export const SurveyModal: React.FC<SurveyModalProps> = ({
  csat_url,
  updateSurveyLastSeenDate
}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    updateSurveyLastSeenDate();
  };

  const handleLoad = () => {
    ReactGA.event('viewed_survey', {
      display_date: new Date().toISOString()
    });
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      onLoad={handleLoad}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Hello! Have a moment?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          title="hubspot_survey"
          height="800"
          width="100%"
          src={csat_url}
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SurveyModal;
