import * as React from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import ReactGA from 'react-ga4';

export const DateSelector: React.FC<{
  datetimeHandler: any;
}> = ({ datetimeHandler }) => {
  const [fromDatetime, setFromDatetime] = useState(null);
  const [toDatetime, setToDatetime] = useState(null);

  useEffect(() => {
    datetimeHandler(fromDatetime, toDatetime);
  }, [fromDatetime, toDatetime]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Button
          onClick={() => {
            ReactGA.event('cleared_search', {
              method: 'date'
            });
            setFromDatetime(null);
            setToDatetime(null);
          }}
          variant="secondary"
          style={{ marginRight: '10px' }}
        >
          Clear Dates
        </Button>
        <label style={{ marginRight: '10px' }}>From:</label>
        <DateTimePicker
          onChange={setFromDatetime}
          value={fromDatetime}
          yearPlaceholder={'yyyy'}
          monthPlaceholder={'mm'}
          dayPlaceholder={'dd'}
          disableClock={true}
          format={'y-MM-dd'}
        />
        <label style={{ marginRight: '10px', marginLeft: '10px' }}>To:</label>
        <DateTimePicker
          onChange={setToDatetime}
          value={toDatetime}
          yearPlaceholder={'yyyy'}
          monthPlaceholder={'mm'}
          dayPlaceholder={'dd'}
          disableClock={true}
          format={'y-MM-dd'}
        />
      </div>
    </>
  );
};
