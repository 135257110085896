export const gtagId = 'G-MCKYKMBPPT';
// this was gtag used for development
//export const gtagId = 'G-FXESZ3RWH0';
export const consentKey = 'consent';

export enum ConsentStatus {
  Granted = 'granted',
  Denied = 'denied'
}
export const consentConfig = {
  ad_storage: ConsentStatus.Denied,
  ad_user_data: ConsentStatus.Denied,
  ad_personalization: ConsentStatus.Denied,
  analytics_storage: ConsentStatus.Granted
};
export const testerEmails = [
  'defaultconfiguration@mailinator.com',
  'icpconfiguration@mailinator.com',
  'specialconfiguration@mailinator.com',
  'cstudyconfig@mailinator.com'
];
